// We want to funnel all ChakraProvider instances through this component
// so that we can control the Provider props from a single location.

/* eslint-disable react/forbid-elements */

import { ChakraProvider, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { theme } from '@ifixit/carpenter-theme';

interface ChakraDefaultProviderProps {
   children: ReactNode;
   theme?: ThemeConfig | false;
   [key: string]: any;
}

const carpenterTheme = extendTheme(theme);
let isChakraInitialized: boolean = false;

export function ChakraDefaultProvider({ children, ...props }: ChakraDefaultProviderProps) {
   /* eslint-disable-next-line no-restricted-syntax */
   const disableGlobalStyle = isChakraInitialized;
   isChakraInitialized = true;

   return (
      <ChakraProvider
         {...props}
         theme={props.theme || carpenterTheme}
         resetCSS={false}
         disableGlobalStyle={disableGlobalStyle}
      >
         {children}
      </ChakraProvider>
   );
}
