import { useIFixitApiClient } from '@ifixit/ifixit-api-client';
import { useLocalPreference } from '@ifixit/utils-react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
   AuthenticatedUserSchema,
   User,
   fetchAuthenticatedUser,
   userDataLocalKey,
   userKeys,
} from './helpers';

export function useAuthenticatedUser(): UseQueryResult<User | null> {
   const apiClient = useIFixitApiClient();
   const [cachedUserData, setCachedUserData] = useLocalPreference<User | null>(
      userDataLocalKey,
      null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data: any) => AuthenticatedUserSchema.parse(data)
   );
   const query = useQuery({
      queryKey: userKeys.user,
      queryFn: () => {
         const responsePromise = fetchAuthenticatedUser(apiClient).catch(_error => null);
         // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Promises must be awaited, end with...
         responsePromise.then(response => {
            setCachedUserData(response);
         });
         return responsePromise;
      },
      retryOnMount: false,
      staleTime: Number.POSITIVE_INFINITY,
      placeholderData: cachedUserData,
   });

   return query;
}
