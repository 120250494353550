import { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Actions from './actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons/faX';
import { menuOpen, menuClose, fadeIn, fadeOut } from 'Shared/animations';
import { breakpoint, color } from '@ifixit/primitives';

const animDuration = 400;

const Container = styled.div`
   z-index: 1000;
   position: fixed;
   display: flex;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
   max-height: ${props => props.height || '50em'};
   max-width: ${props => props.width || '50em'};
   background: white;
   border-radius: 8px;
   overflow: auto;
   box-shadow:
      0px 16px 36px rgba(16, 22, 26, 0.2),
      0px 3px 10px rgba(16, 22, 26, 0.2);
   animation: ${props => (props.$isClosing ? menuClose : menuOpen)} 0.45s;

   > div {
      flex: 1;
      max-width: 100%;
   }

   @media screen and (max-width: ${breakpoint.md}) {
      max-height: none;
      max-width: none;
      border-radius: 0;

      @media (orientation: landscape) {
         display: block;
      }
   }
`;

const Overlay = styled.div`
   z-index: 999;
   display: ${props => (props.$isOpen || props.$isClosing ? 'block' : 'none')};
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: rgba(0, 3, 6, 0.38);
   cursor: ${props => (props.$isLocked ? 'default' : 'pointer')};
   animation: ${props => (props.$isClosing ? fadeOut : fadeIn)} 0.45s;
`;

const CloseButton = styled.div`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   top: 0;
   right: 0;
   height: 60px;
   width: 60px;
   cursor: pointer;
`;

const mapStateToProps = state => ({
   popupState: state.popupState,
});

const mapDispatchToProps = dispatch => ({
   pushPopup: popup => dispatch(Actions.pushPopup(popup)),
   popPopup: () => dispatch(Actions.popPopup()),
});

class PopupModal extends Component {
   constructor(props) {
      super(props);

      this.state = {
         overlayClosing: false,
         popupStack: props.popupState.popupStack,
      };
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      const { popupState } = nextProps;
      let { popupStack } = popupState;
      const prevPopupStack = prevState.popupStack;
      let newPopupStack = popupStack;

      // If a popup has been closed, animate it closing before popping it from
      // the stack.
      if (prevPopupStack.length > newPopupStack.length) {
         newPopupStack = prevPopupStack.map((popup, index) => ({
            ...popup,
            props: {
               ...popup.props,
               $isClosing: index === prevPopupStack.length - 1,
            },
         }));
      }

      return {
         popupStack: newPopupStack,
         overlayClosing: popupStack.length === 0 && newPopupStack.length > 0,
      };
   }

   componentDidUpdate() {
      const { popupStack } = this.state;
      const body = document.querySelector('body');

      body.style.overflow = popupStack.length > 0 ? 'hidden' : 'auto';

      if (this.state.overlayClosing && popupStack.length > 0) {
         setTimeout(() => {
            this.setState({
               overlayClosing: false,
               popupStack: [],
            });
         }, animDuration);
      }
   }

   render() {
      const { popupStack, overlayClosing } = this.state;

      if (popupStack.length === 0) {
         return null;
      }

      const curPopup = popupStack.at(-1);
      // If we don't want to let the user click the overlay to dismiss the
      // popup, set `isLocked` to true.
      const { isLocked, hideClose, onConfirm, onDecline, onCancel } = curPopup;

      return (
         <Fragment>
            <Overlay
               onClick={isLocked ? null : this.props.popPopup}
               $isClosing={overlayClosing}
               $isOpen={popupStack.length > 0}
               $isLocked={isLocked}
            />
            {popupStack.map((popup, index) => {
               const isOpen = index === popupStack.length - 1;
               const PopupComponent = popup.component;
               if (PopupComponent === undefined) {
                  throw new TypeError(
                     `Popup ${index} missing component: ${popup.dedup}\n${JSON.stringify(popup)}`
                  );
               }

               return (
                  <Container key={`popup-${index}`} $isOpen={isOpen} {...popup.props}>
                     {!isLocked && !hideClose && (
                        <CloseButton
                           onClick={() => {
                              onCancel();
                              this.props.popPopup();
                           }}
                        >
                           <FontAwesomeIcon
                              icon={faX}
                              className="fa-abs-30"
                              color={color.gray[500]}
                           />
                        </CloseButton>
                     )}
                     <PopupComponent
                        index={index}
                        numPopups={popupStack.length}
                        onDecline={onDecline}
                        onConfirm={onConfirm}
                        {...popup.props}
                     />
                  </Container>
               );
            })}
         </Fragment>
      );
   }
}

PopupModal.defaultProps = {
   isLocked: false,
   hideClose: false,
   onConfirm: () => {},
   onDecline: () => {},
   onCancel: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupModal);
