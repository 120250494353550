import { SentryError } from '@ifixit/sentry';
import { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import withShadowRoot from 'Shared/utils/shadow_dom';
import initializeComponent from 'Shared/react-initialize';
import { useAsync, IfPending, IfFulfilled, IfRejected } from 'react-async';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { WPNewsletter } from 'Shared/newsletter';
import { getRecentlySeenMap, updateEntry, resetEntry } from './recently-seen-banners';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

const maxHeight = '500px';
const Placeholder = styled.div`
   height: ${props => props.$sizes.default + 'px'};
   margin-bottom: ${props => props.$sizes.space};
   margin-top: ${props => props.$sizes.space};
   @media screen and (min-width: ${props => props.$sizes.small_width + 'px'}) {
      height: ${props => props.$sizes['small'] + 'px'};
   }
   @media screen and (min-width: ${props => props.$sizes.intermediate_width + 'px'}) {
      height: ${props => props.$sizes['intermediate'] + 'px'};
   }
`;

const RenderBanner = ({ html, space }) => {
   const ref = useRef();

   const intersectionCallback = entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
         updateRecentlySeenBanners(ref);
      }
   };

   useEffect(() => {
      addGAtoLinks(ref);
      setupAnyNewsletters(ref);
      const observer = new IntersectionObserver(intersectionCallback, {
         threshold: 1,
      });
      if (ref.current) {
         observer.observe(ref.current);
      }
      return () => {
         removeGAfromLinks(ref);
         observer.disconnect();
      };
   }, [ref.current]);

   return (
      <div
         ref={ref}
         style={{
            width: '100%',
            overflow: 'hidden',
            maxHeight: maxHeight,
            zIndex: 0,
            position: 'relative',
            marginTop: space,
            marginBottom: space,
         }}
         dangerouslySetInnerHTML={{ __html: html }}
      />
   );
};

const BannerWithShadowDom = withShadowRoot(RenderBanner);

const getHtmlFromApi = props => {
   const { type } = props;
   const currentUrl = new URL(window.location.href);
   const apiUrl = new URL('/campaigns', window.location.origin);

   // Add any existing query params
   for (let pair of currentUrl.searchParams.entries()) {
      apiUrl.searchParams.append(pair[0], pair[1]);
   }

   currentUrl.searchParams;

   apiUrl.searchParams.append('currentUrl', currentUrl.toString());
   apiUrl.searchParams.append('type', type);

   const recentlySeenBanners = getRecentlySeenBanners();
   if (recentlySeenBanners.length > 0) {
      apiUrl.searchParams.append('excludePostids', JSON.stringify(recentlySeenBanners));
   }

   const response = new Request.API_2_0(`${apiUrl.pathname}${apiUrl.search}`)
      .send()
      .then(response => {
         if (!response) {
            throw new SentryError('Failed to fetch HTML');
         }
         return response;
      });

   return response;
};

// Returns an array of postids seen [hideAfterViews] times or more and last seen within 30 days
const getRecentlySeenBanners = () => {
   const recentlySeenMap = getRecentlySeenMap();
   const recentlySeenBanners = [];

   for (const [postid, entry] of Object.entries(recentlySeenMap)) {
      const viewedMultipleTimes = entry.count >= 5;
      const seenRecently = entry.lastSeen > Date.now() - 7 * DAY_IN_MILLISECONDS;

      if (viewedMultipleTimes && seenRecently) {
         recentlySeenBanners.push(postid);
      } else if (!seenRecently) {
         resetEntry(postid);
      }
   }

   return recentlySeenBanners;
};

const updateRecentlySeenBanners = ref => {
   if (!ref.current) {
      return;
   }

   const postidNode = ref.current.querySelector('[data-postid]');
   const postid = postidNode?.dataset?.postid;

   if (!postid) {
      return;
   }

   const shouldUpdate = !postidNode.querySelector('.is-large');

   if (shouldUpdate) {
      updateEntry(postid);
   }
};

const setupAnyNewsletters = ref => {
   if (!ref.current) {
      return;
   }

   const titleNode = ref.current.querySelector('[data-banner-title]');
   const title = titleNode?.dataset?.bannerTitle || 'Banner';

   ref.current.querySelectorAll('.newsletter-sub').forEach(newsLetterEl => {
      new WPNewsletter(newsLetterEl, title);
   });
};

const addGAtoLinks = ref => {
   if (!ref.current) {
      return;
   }
   const links = [...ref.current.querySelectorAll('a')];
   const titleNode = ref.current.querySelector('[data-banner-title]');
   const title = titleNode?.dataset?.bannerTitle;

   if (!title) {
      return;
   }

   const handleClick = trackClick(title);

   links.forEach(link => {
      link.addEventListener('click', handleClick);
      link.eventHandler = handleClick;
   });
};

const trackClick = title => () => {
   trackInPiwikAndGA({
      eventCategory: 'Campaign Banner',
      eventAction: `Campaign Banner - ${title} - Click`,
   });
};

const removeGAfromLinks = ref => {
   if (!ref.current) {
      return;
   }
   const links = [...ref.current.querySelectorAll('a')];

   links.forEach(link => {
      if (link.eventHandler) {
         link.removeEventListener('click', link.eventHandler);
      }
   });
};

export const WPCampaignBanner = props => {
   const { sizes, type } = props;
   const wantsPlaceholder = props.hasCampaigns || false;
   const fallbackComponent = props.fallbackComponent || (() => null);
   const state = useAsync({ promiseFn: getHtmlFromApi, ...props });

   return (
      <>
         <IfPending state={state}>
            {() => (wantsPlaceholder ? <Placeholder $sizes={sizes} type={type} /> : null)}
         </IfPending>
         <IfRejected state={state}>{fallbackComponent}</IfRejected>
         <IfFulfilled state={state}>
            {html => <BannerWithShadowDom html={html} space={sizes.space} />}
         </IfFulfilled>
      </>
   );
};

initializeComponent('WPCampaignBanner', WPCampaignBanner);
