import type { ReactElement } from 'react';
import { captureException } from '@ifixit/sentry';
import {
   MutationCache,
   QueryCache,
   QueryClient,
   QueryClientProvider,
   QueryFunction,
   QueryKey,
} from '@tanstack/react-query';
import { apiClient } from 'Shared/api_client';

/**
 * This default query function allows us to use the `useQuery` hook with only the API route
 * Example:
 *    const { data, isLoading, error } = useQuery({ queryKey: ['guides/123']});
 */
const defaultQueryFn: QueryFunction<unknown, QueryKey> = async ({ queryKey }) => {
   const endpoint = queryKey[0];
   const response = await apiClient.get('' + endpoint);
   return response.data;
};

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         queryFn: defaultQueryFn,
      },
   },
   queryCache: new QueryCache({
      onError: (error, query) => {
         captureException(error, scope => {
            scope.setTag('react-query', 'query');
            scope.setContext('Query', { queryKey: query.queryKey });
            return scope;
         });
         console.error(error, query);
      },
   }),
   mutationCache: new MutationCache({
      onError: (error, variables, context, mutation) => {
         captureException(error, scope => {
            scope.setTag('react-query', 'mutation');
            scope.setContext('Mutation', {
               variables: JSON.stringify(variables, null, 2),
               context: JSON.stringify(context, null, 2),
               mutationKey: mutation.options.mutationKey,
            });
            return scope;
         });
         console.error(error, variables, context, mutation);
      },
   }),
});

export function initializeReactQuery(component: ReactElement): ReactElement {
   return <QueryClientProvider client={queryClient}>{component}</QueryClientProvider>;
}
