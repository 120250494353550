import {
   type Money,
   type ProductVariantID,
   filterNullableItems,
   getVariantIdFromVariantURI,
} from '@ifixit/helpers';
import { type CartLineItem } from '@ifixit/shopify-cart-sdk';
import { useCart } from '@ifixit/shopify-cart-sdk/hooks';
import {
   getCategoriesFromAnalyticsDimensions,
   parseAnalyticsDimensionsMetafield,
   parseOemPartnershipMetafield,
} from '@ifixit/shopify-cart-sdk/models/cart';
import {
   type FindMerchProductQuery,
   useShopifyStorefrontClient,
} from '@ifixit/shopify-storefront-client';
import { useQuery } from '@tanstack/react-query';
import type { MerchProduct, MerchProductVariant } from '../types';
import { useGetUserPrice } from '../../commerce';

const ORBIT_HANDLE = 'orbit-t-shirt';

export function useOrbitShirt(): MerchProduct | null {
   const { data: cart } = useCart();
   const { client, storeCode } = useShopifyStorefrontClient();
   const getUserPrice = useGetUserPrice({ storeCode });
   const { data } = useQuery({
      queryKey: ['find-merch-product', storeCode, ORBIT_HANDLE],
      queryFn: async (): Promise<MerchProduct | null> => {
         const queryResult = await client.findMerchProduct({ handle: ORBIT_HANDLE });
         return createMerchProduct(queryResult);
      },
   });
   const orbitShirtItemcodes = data?.variants.map(v => v.lineItem.itemcode) ?? [];
   const hasShirtInCart =
      !cart || cart.lineItems.some(item => orbitShirtItemcodes.includes(item.itemcode));
   return data && !hasShirtInCart ? data : null;

   function createMerchProduct(queryResult: FindMerchProductQuery): MerchProduct | null {
      const product = queryResult.product;
      if (product == null) {
         return null;
      }
      const variants = product.variants.nodes;
      const defaultVariant = variants[0];
      const merchProduct: MerchProduct = {
         imageSrc: defaultVariant.image?.url,
         imageAlt: defaultVariant.image?.altText,
         handle: product.handle,
         variants: filterNullableItems(
            variants.map(apiVariant => createMerchProductVariant({ product, apiVariant }))
         ),
         title: product.title,
      };
      if (merchProduct.variants.length === 0) {
         return null;
      }
      return merchProduct;
   }

   type ApiVariant = NonNullable<FindMerchProductQuery['product']>['variants']['nodes'][0];
   function createMerchProductVariant({
      apiVariant,
      product,
   }: {
      apiVariant: ApiVariant;
      product: NonNullable<FindMerchProductQuery['product']>;
   }): MerchProductVariant | null {
      const enabled = apiVariant.enabled?.value === 'true';
      const inStock = apiVariant.quantityAvailable && apiVariant.quantityAvailable > 0;
      const apparelSize = apiVariant.selectedOptions.find(
         options => options.name === 'Apparel Size'
      );
      if (!enabled || !inStock || !apiVariant.id || !apiVariant.sku || !apparelSize) {
         return null;
      }
      const globalVariantId = apiVariant.id as ProductVariantID;
      const variantid = getVariantIdFromVariantURI(globalVariantId);
      const proPricesByTier = parsePriceTiers(
         apiVariant.proPricesByTier,
         apiVariant.price.currencyCode
      );
      const userPrice = getUserPrice({
         price: apiVariant.price,
         compareAtPrice: apiVariant.compareAtPrice,
         proPricesByTier,
      });
      const analyticsDimensions = parseAnalyticsDimensionsMetafield(apiVariant.analyticsDimensions);
      const oemPartnership = parseOemPartnershipMetafield(product.oemPartnership);
      const lineItem: CartLineItem = {
         name: product.title,
         variantTitle: apiVariant.title,
         itemcode: apiVariant.sku,
         shopifyVariantId: globalVariantId,
         quantity: 1,
         imageSrc: apiVariant.image?.url,
         price: userPrice.price,
         compareAtPrice: userPrice.compareAtPrice,
         categories: getCategoriesFromAnalyticsDimensions(analyticsDimensions),
         url: `/products/${product.handle}?variant=${variantid}`,
         fulfiller: analyticsDimensions?.fulfiller,
         oemPartnership: oemPartnership,
      };
      const variant: MerchProductVariant = {
         apparelSize: apparelSize.value,
         lineItem,
         proPricesByTier,
      };
      return variant;
   }
}

function parsePriceTiers(
   priceTiersMetafield: { value: string } | null | undefined,
   currencyCode: string
): Record<string, Money> {
   let priceTiers: Record<string, number>;
   try {
      priceTiers = JSON.parse(priceTiersMetafield?.value ?? '');
   } catch {
      return {};
   }
   return Object.entries(priceTiers).reduce(
      (acc, [key, value]) => {
         acc[key] = {
            amount: value,
            currencyCode: currencyCode,
         };
         return acc;
      },
      {} as Record<string, Money>
   );
}
