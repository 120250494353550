import { lazy, Suspense, useEffect } from 'react';
import type { ElementType } from 'react';
import {
   Modal,
   ModalOverlay,
   ModalContent,
   Skeleton,
   UseDisclosureProps,
   useDisclosure,
   useBreakpointValue,
} from '@chakra-ui/react';
import {
   AlgoliaHitProps,
   TreeNavigationProps,
   WorkbenchDevice,
   SelectionProps,
   AlgoliaConfig,
} from './types';
import { addRecentDevice, type RecentDevice, safeLocalStorage } from '@ifixit/utils';
import { trackAnalyticsCustomEvent } from '@ifixit/analytics';

const NUM_RECENT_DEVICES = 2;
const DevicePicker = lazy(() => import('./DevicePicker'));

export const DevicePickerModal = ({
   onSelect,
   algoliaConfig,
   objectName,
   objectNamePlural,
   langid,
   preferredStore,
   devicesInWorkbench,
   initialDevice,
   selectionProps,
   treeNavigationProps,
   showUncategorizedDevices,
   titleInstruction,
   rootDevice,
   ifixitOrigin,
   wikiTextComponent,
   analyticsKey,
   ...disclosureProps
}: {
   onSelect: (_device: AlgoliaHitProps) => void;
   algoliaConfig: AlgoliaConfig;
   objectName: string;
   objectNamePlural: string;
   langid: string;
   preferredStore: string;
   devicesInWorkbench?: WorkbenchDevice[];
   initialDevice?: string;
   selectionProps?: SelectionProps;
   treeNavigationProps?: TreeNavigationProps;
   showUncategorizedDevices?: boolean;
   titleInstruction?: string;
   rootDevice?: string;
   ifixitOrigin?: string;
   wikiTextComponent?: ElementType;
   analyticsKey: string;
} & UseDisclosureProps) => {
   const { isOpen, onClose } = useDisclosure(disclosureProps);
   const isCentered = useBreakpointValue({ base: false, sm: true });
   const recentDevices = (safeLocalStorage.getJson<RecentDevice[]>('recentDevices') || []).slice(
      0,
      NUM_RECENT_DEVICES
   );
   const onSubmit = (device: AlgoliaHitProps) => {
      if (device.wikiid) {
         addRecentDevice(device);
      }
      onSelect(device);
   };

   const trackedClose = () => {
      trackAnalyticsCustomEvent({
         eventCategory: analyticsKey,
         eventAction: analyticsKey + ' - Device Picker - Closed without selection - Click',
         eventName: '',
      });
      onClose();
   };

   useEffect(() => {
      if (isOpen) {
         trackAnalyticsCustomEvent({
            eventCategory: analyticsKey,
            eventAction: analyticsKey + ' - Device Picker - Opened - Click',
            eventName: '',
         });
      }
   }, [isOpen]);

   return (
      <Modal
         isOpen={isOpen}
         onClose={trackedClose}
         scrollBehavior="inside"
         isCentered={isCentered}
         closeOnEsc
         closeOnOverlayClick
      >
         <ModalOverlay />
         <ModalContent
            p={0}
            my={0}
            height={{ base: '90dvh', sm: '85vh' }}
            maxHeight="800px"
            width="auto"
            maxWidth="100%"
            alignSelf={{ base: 'flex-end', sm: 'auto' }}
            borderRadius="8px"
         >
            <Suspense fallback={<Skeleton height="100%" width="100%" />}>
               <DevicePicker
                  algoliaConfig={algoliaConfig}
                  onSubmit={onSubmit}
                  onCancel={onClose}
                  objectName={objectName}
                  objectNamePlural={objectNamePlural}
                  langid={langid}
                  devicesInWorkbench={devicesInWorkbench}
                  initialDevice={initialDevice}
                  selectionProps={selectionProps}
                  treeNavigationProps={treeNavigationProps}
                  showUncategorizedDevices={showUncategorizedDevices}
                  titleInstruction={titleInstruction}
                  preferredStore={preferredStore}
                  recentDevices={recentDevices}
                  rootDevice={rootDevice}
                  ifixitOrigin={ifixitOrigin}
                  wikiTextComponent={wikiTextComponent}
                  analyticsKey={analyticsKey}
               />
            </Suspense>
         </ModalContent>
      </Modal>
   );
};
