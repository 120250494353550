import type { Event, Integration } from '@sentry/types';

const IgnoredUserAgents = [
   /yeti/i, // Yeti web crawler
   /spider/i, // spiders (YisouSpider, Baiduspider, etc)
   /crawler/i, // misc crawlers
];

function shouldIgnoreUserAgent(userAgent: string) {
   const userAgentLower = userAgent.toLowerCase();
   return IgnoredUserAgents.some(pattern => pattern.test(userAgentLower));
}

export function ignoreUserAgentIntegration(): Integration {
   return {
      name: 'ignoreUserAgentIntegration',
      processEvent(event: Event) {
         if (typeof window !== 'undefined' && shouldIgnoreUserAgent(window.navigator.userAgent)) {
            return null;
         }

         return event;
      },
   };
}
