import { Box, Image } from '@chakra-ui/react';
import { invariant } from '@ifixit/helpers';
import { FlagCountryCode } from '@ifixit/icons';
import { useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { CartDrawer, SupportLink, type SupportLinkProps } from '@ifixit/ui';
import { ReactNode } from 'react';

type CartDrawerWrapperProps = {
   flagCountryCode: FlagCountryCode;
   locale: SupportLinkProps['localeCode'] | null;
   storeCode: string;
};

export function CartDrawerWrapper({ flagCountryCode, locale, storeCode }: CartDrawerWrapperProps) {
   const { cartUrl } = useShopifyStorefrontClient();
   invariant(cartUrl, 'cart url must be provided for carpenter cart drawer');
   invariant(flagCountryCode, 'flag country code must be provided for carpenter cart drawer');
   invariant(locale, 'locale must be provided for carpenter cart drawer');
   const supportLinkComponent = getSupportLinkComponent({ localeCode: locale });

   return (
      <Box ml={4} mr={{ base: 3, md: 4 }}>
         <CartDrawer
            flagCode={flagCountryCode}
            localeCode={locale}
            ImageComponent={CartImage}
            supportLinkComponent={supportLinkComponent}
            viewCartUrl={cartUrl}
            storeCode={storeCode}
         />
      </Box>
   );
}

function getSupportLinkComponent({ localeCode }: { localeCode: SupportLinkProps['localeCode'] }) {
   return function WithLocale(children: ReactNode) {
      return <SupportLink localeCode={localeCode}>{children}</SupportLink>;
   };
}

function CartImage({ alt, src }: { alt?: string; src: string }) {
   const srcUrl = new URL(src);
   const params = new URLSearchParams(srcUrl.search);
   params.set('width', '70');
   const newSrc = `${srcUrl.origin}${srcUrl.pathname}?${params.toString()}`;
   return <Image src={newSrc} alt={alt} objectFit="cover" />;
}
