import { Box, BoxProps, Button, Flex, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { type ElementType } from 'react';
import type { CrossSellProduct } from './types';
import { ProductVariantPrice } from '../../commerce';
import { CartLineItemImage } from '../drawer/CartLineItemImage';
import { useCartDrawer } from '../drawer/hooks/useCartDrawer';
import type { CartImageComponent } from '../types';
import { useTranslations } from '@ifixit/i18n';

type CrossSellItemProps = BoxProps & {
   ImageComponent: CartImageComponent;
   item: CrossSellProduct;
   onAddToCart: (item: CrossSellProduct) => void;
   isAddingToCart: boolean;
   isAddToCartDisabled: boolean;
   linkComponent?: ElementType;
   localeCode: string;
};

export function CrossSellItem({
   ImageComponent,
   item,
   onAddToCart,
   isAddingToCart,
   isAddToCartDisabled,
   linkComponent,
   localeCode,
   ...otherProps
}: CrossSellItemProps) {
   const { onClose } = useCartDrawer();
   const t = useTranslations();

   const nextFontSizeSm = '14px'; // Bypasses root font size difference between NextJS and Carpenter

   return (
      <Box bgColor="brand.100" py="4" px="3" rounded="lg" {...otherProps}>
         {item.marketingHeading && (
            <Text fontSize={nextFontSizeSm} color="brand.500" fontWeight="semibold">
               {item.marketingHeading}
            </Text>
         )}
         <Text fontSize={nextFontSizeSm} color="brand.500" fontWeight="normal">
            {t('components.CrossSell.addProduct')}
         </Text>
         <LinkBox mt="3" role="group">
            <HStack align="flex-start" spacing="3">
               <CartLineItemImage
                  ImageComponent={ImageComponent}
                  src={item.imageSrc}
                  alt={item.name}
                  borderWidth="0"
               />
               <Flex direction="column" align="flex-start">
                  <LinkOverlay
                     as={linkComponent}
                     href={`/products/${item.handle}`}
                     onClick={onClose}
                     fontSize={nextFontSizeSm}
                     fontWeight="semibold"
                     color="currentColor"
                     _groupHover={{
                        textDecoration: 'underline',
                     }}
                  >
                     {item.marketingTitle ?? item.name}
                  </LinkOverlay>
                  <Text fontSize={nextFontSizeSm} mt="1.5">
                     {item.marketingBlurb}
                  </Text>
               </Flex>
            </HStack>
            <Flex mt="2" justify="flex-end">
               <ProductVariantPrice
                  price={item.price}
                  compareAtPrice={item.compareAtPrice}
                  proPricesByTier={item.proPricesByTier}
                  size="small"
                  localeCode={localeCode}
               />
            </Flex>
         </LinkBox>
         <Flex mt="3" justifyContent="flex-end">
            <Button
               isLoading={isAddingToCart}
               isDisabled={isAddToCartDisabled}
               onClick={() => onAddToCart(item)}
               width="full"
               size="sm"
               variant="cta"
               data-testid="x-sell-add-to-cart-button"
            >
               {item.isPreorder ? t('CartPage.preorder') : t('CartPage.addToCart')}
            </Button>
         </Flex>
      </Box>
   );
}
